import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";

import { ConferenceMode } from "@auvious/rtc";

import { InteractionService } from "../../services/interaction.service";
import {
  ErrorPageCode,
  PARAM_CALL_TYPE,
  PARAM_COBROWSE_AVAILABLE,
  PARAM_CONVERSATION_DESTINATION,
  PARAM_CONVERSATION_ID,
  PARAM_CONVERSATION_ORIGIN,
  PARAM_KIOSK_MODE,
  PARAM_ROOM_ID,
  PARAM_WIDGET_ORIGIN_URL,
  PARAM_CUSTOMER_ID,
  DEFAULT_CUSTOMER_ID,
  PARAM_CONVERSATION_CHANNEL,
  PARAM_MESSAGING,
} from "../../app.enums";
import { Interaction } from "../../models/Interaction";

import {
  ApplicationService,
  ApplicationTypeEnum,
  ConversationOriginEnum,
  AuviousRtcService,
  ConversationTypeEnum,
  UserRoleEnum,
  UserService,
  AppConfigService,
  ConversationDestinationEnum,
  UserCapabilityEnum,
  ConversationMetadataEnum,
  CLAIM_CONFERENCE_ID,
  CLAIM_INTERACTION_ID,
  OriginModeEnum,
  ConversationChannelEnum,
  AppointmentService,
  CLAIM_APPOINTMENT_ID,
} from "../../../core-ui";
import { AuthState } from "../../../core-ui/models/AuthState";
import { IInteraction } from "../../../core-ui/models/IInteraction";
import { AnalyticsService } from "../../../core-ui/services/analytics.service";
import { GenesysInteraction } from "../../../app/models";

@Injectable({ providedIn: "root" })
export class InteractionRoomResolver {
  private authState: AuthState;

  constructor(
    private configService: AppConfigService,
    private interactionService: InteractionService,
    private applicationService: ApplicationService,
    private rtcService: AuviousRtcService,
    private router: Router,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private appointmentService: AppointmentService
  ) {}

  private findParam(
    route: ActivatedRouteSnapshot,
    param: string,
    authStateParam: string
  ): string {
    if (route.queryParamMap.has(param)) {
      return route.queryParamMap.get(param);
    }
    if (this.authState && authStateParam in this.authState) {
      return this.authState[authStateParam];
    }
    if (route.queryParamMap.has("state")) {
      this.authState = AuthState.fromSerializedState(
        route.queryParamMap.get("state")
      );
      if (authStateParam in this.authState) {
        return this.authState[authStateParam];
      }
    }
    return null;
  }

  private async setAppointmentMetadataToInteraction(
    interaction: IInteraction
  ): Promise<IInteraction> {
    const appointment = await this.appointmentService.get(
      interaction.getAppointmentId()
    );
    this.appointmentService.notifyChange(appointment);
    if (appointment.metadata) {
      Object.keys(appointment.metadata).forEach((key) => {
        interaction.setMetadataItem(
          key as ConversationMetadataEnum,
          appointment.metadata[key]
        );
      });
    }
    return interaction;
  }

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<IInteraction> {
    let interaction: IInteraction;
    const user = this.userService.getActiveUser();

    if (user.hasRole(UserRoleEnum.customer)) {
      interaction =
        this.interactionService.getActiveInteraction() ||
        new Interaction(
          user.getClaim(CLAIM_CONFERENCE_ID),
          user.getClaim(CLAIM_INTERACTION_ID)
        );
      this.interactionService.setActiveInteraction(interaction);
    } else {
      if (this.interactionService.isActiveInteractionAvailable()) {
        interaction = this.interactionService.getActiveInteraction();
        if (!!interaction.getAppointmentId()) {
          interaction = await this.setAppointmentMetadataToInteraction(
            interaction
          );
        }
        this.analyticsService.trackCallRequested(interaction);
        return interaction;
      }

      // joining as a guest agent or refresh
      const interactionId = this.findParam(
        route,
        PARAM_CONVERSATION_ID,
        "conversationId"
      );
      if (!!interactionId) {
        interaction = await this.interactionService.retrieveInteractionData(
          interactionId
        );
      }

      // construct interaction from query params
      const room = this.findParam(route, PARAM_ROOM_ID, "room");
      if (!!room) {
        if (!!interaction) {
          interaction.setRoom(room);
        } else {
          if (
            user.hasRole(UserRoleEnum.agent) &&
            this.applicationService.getActiveApplication().getType() ===
              ApplicationTypeEnum.Genesys
          ) {
            interaction = new GenesysInteraction({
              room,
              legacy: true,
              communicationId: interactionId ?? room,
              conversationId: interactionId ?? room,
              customerId: DEFAULT_CUSTOMER_ID,
              agentId: user.getId(),
              origin: ConversationOriginEnum.CHAT,
              type: ConversationTypeEnum.videoCall,
              channel: ConversationChannelEnum.unknown,
              customerEmail: "replace-me@auvious.video",
              customerPhone: "",
            });
          } else {
            interaction = new Interaction(room, interactionId);
          }

          switch (this.applicationService.getActiveApplication().getType()) {
            case ApplicationTypeEnum.Test:
            case ApplicationTypeEnum.StandaloneOpenID:
            case ApplicationTypeEnum.Genesys:
              // agent creates the interaction
              interaction.setOrigin(ConversationOriginEnum.EMBEDDED);
              break;
          }
        }
      }

      // if we haven't managed to discover an interaction yet, create one
      if (!interaction) {
        switch (this.applicationService.getActiveApplication().getType()) {
          case ApplicationTypeEnum.Test:
          case ApplicationTypeEnum.StandaloneOpenID:
            const conference = await this.rtcService.createConference({
              mode: ConferenceMode.ROUTER,
            });
            interaction = new Interaction(conference.name, interactionId);
            break;
        }
      }
    }

    if (!interaction) {
      this.router.navigate(["/error", ErrorPageCode.ROOM_NOT_FOUND], {
        queryParamsHandling: "preserve",
      });
    }

    const kioskMode = this.findParam(route, PARAM_KIOSK_MODE, "kioskMode");
    if (kioskMode === "true") {
      interaction.setOriginMode(OriginModeEnum.kiosk);
    }

    const parentFrameUrl = this.findParam(route, PARAM_WIDGET_ORIGIN_URL, "");
    if (parentFrameUrl) {
      interaction.setParentFrameUrl(parentFrameUrl);
      interaction.setOrigin(ConversationOriginEnum.WIDGET);
    }

    const type = this.findParam(route, PARAM_CALL_TYPE, "conversationType");
    if (type) {
      interaction.setType(type as ConversationTypeEnum);
    }

    const origin = this.findParam(
      route,
      PARAM_CONVERSATION_ORIGIN,
      "conversationOrigin"
    );
    if (origin) {
      interaction.setOrigin(origin as ConversationOriginEnum);
    }

    if (user.hasClaim(CLAIM_APPOINTMENT_ID)) {
      interaction.setOrigin(ConversationOriginEnum.APPOINTMENT);
      interaction.setAppointmentId(user.getClaim(CLAIM_APPOINTMENT_ID));
      user.addCapability(UserCapabilityEnum.messaging);
    }

    const destination = this.findParam(
      route,
      PARAM_CONVERSATION_DESTINATION,
      "conversationDestination"
    );
    if (destination) {
      interaction.setDestination(destination as ConversationDestinationEnum);
    }

    const channel = this.findParam(
      route,
      PARAM_CONVERSATION_CHANNEL,
      "conversationChannel"
    );
    if (channel) {
      interaction.setChannel(channel as ConversationChannelEnum);
    }

    const cobrowseAvailable = this.findParam(
      route,
      PARAM_COBROWSE_AVAILABLE,
      ""
    );
    if (cobrowseAvailable === "true") {
      user.addCapability(UserCapabilityEnum.coBrowse);
    }

    const messagingEnabled = this.findParam(route, PARAM_MESSAGING, "");
    if (messagingEnabled === "true") {
      user.addCapability(UserCapabilityEnum.messaging);
    }

    this.interactionService.setActiveInteraction(interaction);

    const customerId = this.findParam(route, PARAM_CUSTOMER_ID, "customerId");
    if (customerId) {
      interaction.setCustomerId(customerId);
    }

    if (
      user.hasRole(UserRoleEnum.agent) &&
      !user.hasRole(UserRoleEnum.guestAgent)
    ) {
      this.analyticsService.trackCallRequested(interaction);
    }
    return interaction;
  }
}
