<section class="container">
  <div *ngIf="!loading">
    <div class="tools-right" *ngIf="isReloadAvailable">
      <button
        avButton
        ofType="basic"
        class="fx-row fx-align-center"
        (click)="getInteractions()"
      >
        <av-icon size="xs" name="reload"></av-icon>
        Refresh
      </button>
    </div>
    <ng-container *ngIf="isInitialized">
      <div class="step text-center">
        <div class="panel-container">
          <av-card type="info" *ngIf="isHelpVisible">
            <av-card-body type="slim">
              <h3 class="container-pad-md" translate>
                Please enable one of the available actions in the settings page.
              </h3>
            </av-card-body>
          </av-card>

          <!-- schedule -->
          <ng-container *ngIf="isSchedulingAvailable">
            <av-tooltip
              *ngIf="isActiveActionEmpty"
              [message]="
                isSchedulingNotConfigured
                  ? 'Go to settings to configure'
                  : 'Schedule a video call'
              "
              position="bottom"
              [class.panel-button-callback]="
                isVideoCallAvailable || isCobrowseAvailable
              "
            >
              <button
                [disabled]="isSchedulingDisabled"
                class="launch-button"
                aria-label="Schedule a video call"
                (click)="toggleCallback()"
                data-tid="welcome/launch-callback"
                id="btn-callback"
              >
                <div class="launch-button-body">
                  <div class="calendar"></div>
                </div>
              </button>
            </av-tooltip>
            <app-callback
              *ngIf="isCallbackVisible"
              [inCall]="false"
              [hover]="false"
              (close)="toggleCallback()"
            >
            </app-callback>
          </ng-container>

          <!-- audio/video call -->
          <button
            *ngIf="isActiveActionEmpty && isVideoCallAvailable"
            [class.ripple-container]="isCallEnabled"
            class="launch-button"
            [disabled]="!isCallEnabled"
            data-tid="welcome/launch-conference"
            aria-label="Start a video call"
            (click)="startCall()"
            autofocus
          >
            <div class="launch-button-body" id="btn-generate-room-and-join">
              <div *ngIf="!isConversationAudio" class="cam"></div>
              <div *ngIf="isConversationAudio" class="mic"></div>
            </div>
          </button>

          <!-- cobrowse -->
          <ng-container *ngIf="isCobrowseAvailable">
            <av-tooltip
              position="bottom"
              *ngIf="isActiveActionEmpty"
              [class.panel-button-cobrowse]="isVideoCallAvailable"
              message="Start a co-browse session"
            >
              <button
                class="launch-button"
                [disabled]="isCobrowseDisabled"
                (click)="toggleCobrowse()"
                data-tid="welcome/launch-cobrowse"
                aria-label="Start a co-browse session"
                autofocus
              >
                <div
                  class="launch-button-body"
                  id="btn-cobrowse"
                  data-tid="welcome/launch-cobrowse"
                >
                  <div class="cobrowse"></div>
                </div>
              </button>
            </av-tooltip>

            <app-cobrowse-launcher
              *ngIf="isCobrowseVisible"
              [interaction]="interaction()"
              (started)="cobrowseStarted($event)"
              (closed)="cobrowseCanceled()"
            ></app-cobrowse-launcher>
          </ng-container>
        </div>

        <ng-container *ngIf="isActiveActionEmpty">
          <ng-container
            *ngIf="isOriginCall || isOriginChat || isOriginInteractionWidget"
          >
            <h1 class="title" translate>Click to create a video room</h1>
            <h3 translate>You can invite the customer once you join</h3>
          </ng-container>
          <ng-container *ngIf="isOriginWidget && !isConversationCallback">
            <ng-container *ngIf="!isConversationChat">
              <h1 translate>Hurry up! The customer is waiting.</h1>
              <h1 *ngIf="isConversationVideo" class="title" translate>
                Click to join the video room
              </h1>
              <h1 *ngIf="isConversationAudio" class="title" translate>
                Click to join the Voice call
              </h1>
              <h3 translate>The customer will join once you join</h3>
            </ng-container>
            <ng-container *ngIf="isConversationChat">
              <h1 *ngIf="isVideoCallAvailable" translate>
                Click to escalate this chat session to Video Call
              </h1>
              <h1
                *ngIf="isCobrowseAvailable && !isVideoCallAvailable"
                translate
              >
                Escalate chat to Co-browse
              </h1>
              <h3 translate>The customer will join once you join</h3>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="isConversationCallback">
            <h1 class="title" translate>
              Click to join the scheduled video call
            </h1>
            <h3 translate>The customer is already waiting for you</h3>
          </ng-container>
          <ng-container *ngIf="!isInteractionAvailable">
            <h1 class="title">
              <span translate>Hello</span>&nbsp;<span *ngIf="!!user">{{
                name
              }}</span>
            </h1>
            <h3 *ngIf="isVideoCallAvailable" translate>
              Waiting for the next customer...
            </h3>
            <h3 *ngIf="!isVideoCallAvailable && isCobrowseAvailable" translate>
              Start a co-browse session
            </h3>
            <h3
              *ngIf="
                !isVideoCallAvailable &&
                !isCobrowseAvailable &&
                isSchedulingAvailable
              "
              translate
            >
              Schedule a video call
            </h3>
          </ng-container>
          <ng-container *ngIf="isOriginAppointment">
            <h1 class="title" translate>Click to join the appointment</h1>
            <h3 translate>The customer is already waiting for you</h3>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
</section>
