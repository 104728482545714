import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { InteractionService } from "../../services/interaction.service";
import { PARAM_ROOM_ID } from "../..//app.enums";
import { AuthState } from "../../../core-ui/models/AuthState";
import { IInteraction } from "../../../core-ui/models/IInteraction";
import {
  ActivityIndicatorService,
  AgentParam,
  AppConfigService,
  ApplicationService,
  AuviousRtcService,
  ConversationDestinationEnum,
  ConversationOriginEnum,
  UserService,
} from "../../../core-ui";
import { ConferenceMode } from "@auvious/rtc";
import {
  DigitalConnectInteraction,
  IDigitalConnectInteraction,
} from "../../models/DigitalConnectInteraction";

@Injectable({ providedIn: "root" })
export class InteractionResolver {
  private info: IInteraction;
  private authState: AuthState;

  constructor(
    private interactionService: InteractionService,
    private activity: ActivityIndicatorService,
    private applicationService: ApplicationService,
    private router: Router,
    private config: AppConfigService,
    private rtcService: AuviousRtcService,
    private userService: UserService
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<IInteraction> {
    try {
      this.activity.loading(true, "Discovering");

      // most probably we are coming from a 'cancel'/'back' on the room.
      if (this.interactionService.isActiveInteractionAvailable()) {
        // interaction was created on-the-fly so clear it
        if (
          this.applicationService
            .getActiveApplication()
            .canCallWithoutActiveInteraction()
        ) {
          this.interactionService.clearActiveInteraction();
        } else {
          // incoming interaction
          this.activity.loading(false);
          return this.interactionService.getActiveInteraction();
        }
      }

      this.authState = AuthState.fromSerializedState(
        route.queryParamMap.get("state")
      );

      // if the destination is mobile office or a conversation widget or talkdesk card, we need the conversation id since the
      // app will run only one time and will get destroyed when ended.
      let interactionId = this.authState?.conversationId;
      if (
        ![
          ConversationDestinationEnum.MOBILE_OFFICE,
          ConversationDestinationEnum.INTERACTION_WIDGET,
          ConversationDestinationEnum.TALKDESK_CARD,
        ].includes(this.authState?.conversationDestination)
      ) {
        // don't take into account the conversation id from state param and get the fresh one
        interactionId = null;
      }

      this.info = !!interactionId
        ? await this.interactionService.retrieveInteractionData(interactionId)
        : await this.interactionService.discoverActiveInteraction();

      if (this.info) {
        // update destination in case we come from interaction-widget, mobile-office etc
        if (!!this.authState?.conversationDestination) {
          this.info.setDestination(this.authState.conversationDestination);
        }
        this.interactionService.setActiveInteraction(this.info);
      } else if (
        this.authState?.conversationId &&
        this.authState?.conversationDestination ===
          ConversationDestinationEnum.TALKDESK_CARD
      ) {
        // Talkdesk does not provide an API to get the interaction details and if we are not in a digital connection interaction
        // we have no way of knowing the interaction. We do need the talkdesk interaction id though, so for now let's create an empty
        // interaction just to hold the id
        const data: IDigitalConnectInteraction = {
          conversationId: this.authState.conversationId,
          interactionId: this.authState.conversationId,
          created: new Date().toISOString(),
          agentId: this.userService.getActiveUser().getId(),
          customerId: this.authState?.customerId,
          customProperties: {
            conversationType: this.authState?.conversationType,
          },
        };
        this.info = new DigitalConnectInteraction(data);
        this.info.setDestination(this.authState.conversationDestination);
        this.info.setOrigin(ConversationOriginEnum.EMBEDDED);
        this.interactionService.setActiveInteraction(this.info);
      }

      if (!this.info) {
        return null;
      }

      if (this.isAutoJoin) {
        // coming from mobile office, we will not have a room
        // unless it's a refresh and the room was stored in customer attributes
        if (!this.info.getRoom()) {
          const conference = await this.rtcService.createConference({
            mode: ConferenceMode.ROUTER,
          });
          this.info.setRoom(conference.name);
          // update active interaction now that it contains the room
          this.interactionService.setActiveInteraction(this.info);
        }
        this.activity.loading(false);
        // redirect to call
        this.router.navigate(["/a"], {
          queryParams: {
            [PARAM_ROOM_ID]: this.info.getRoom(),
            ...route.queryParams,
          },
        });
      } else {
        this.activity.loading(false);
        return this.info;
      }
    } catch (ex) {
      this.activity.loading(false);
      return null;
    }
  }

  get isAutoJoin() {
    return (
      this.info.getDestination() ===
        ConversationDestinationEnum.MOBILE_OFFICE ||
      (this.info.getDestination() ===
        ConversationDestinationEnum.INTERACTION_WIDGET &&
        this.config.agentParamEnabled(AgentParam.AUTO_JOIN_CALL))
    );
  }
}
